import React from 'react';
import CarouselComponent from './CarouselComponent';

const Sliders = () => {
    
    return (
        <>

<section className="slider-section bg-dark pt-80 rpt-100">
    <div className="main-slider-active">
        <CarouselComponent />
    </div>
</section>
        </>
    );
};

export default Sliders;