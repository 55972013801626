import React, { useState } from 'react';
import Social from './Social';

const Sidebar = () => {
    const [response, setResponse] = useState(null);

    const sidebarToggle = () => {
        document.querySelector('body').classList.toggle('side-content-visible');
        return true;
    }

    const [enqData, setFormData] = useState({
        enqname: '',
        enqemail: '',
        enqmobile: '',
        enqcompany: '',
        enqcountry: '',
        enqmessage: ''
      });
      
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...enqData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setResponse(null);
        try {
          const res = await fetch('/api/inquiry.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(enqData),
          });
          const result = await res.json();
          //console.log(result);
          setResponse(result.message); 
          setTimeout(() => {
            setResponse('');
            }, 3000);
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
    return (
        <section className="hidden-bar">
            <div className="inner-box text-center">
                <div className="cross-icon"><span onClick={sidebarToggle} className="bi bi-x-lg"></span></div>
                <div className="title">
                    <h4>Send an Inquiry</h4>
                </div>
                <div className="appointment-form">
                    <form method="post" onSubmit={handleSubmit}>
                        <div className="form-group"><input type="text" name="enqname" placeholder="Your Name" onChange={handleChange} required /></div>
                        <div className="form-group"><input type="text" name="enqmobile" placeholder="Your Mobile" onChange={handleChange} required/></div>
                        <div className="form-group"><input type="email" name="enqemail" placeholder="Email Address" onChange={handleChange} required/></div>
                        <div className="form-group"><input type="text" name="enqcompany" placeholder="Company Name" onChange={handleChange} required/></div>
                        <div className="form-group"><input type="text" name="enqcountry" placeholder="Your Country" onChange={handleChange} required/></div>
                        <div className="form-group"><textarea placeholder="Message" name='enqmessage' rows="5" onChange={handleChange} required></textarea></div>
                        <div className="form-group"><button type="submit" className="theme-btn">Submit now</button></div>
                    </form>
                </div>
                {response && <div className='alert alert-info mt-10'> {response } </div>}
                <div className="social-style-one"><Social /></div>
            </div>
        </section>        
    );
};

export default Sidebar;