import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Modal, Image } from 'react-bootstrap';
import Seo from '../components/Seo';
import Loader from '../components/Loader';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const Product = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { id } = useParams();
    const [item, setItem] = useState(null);
    
    useEffect(() => {
        fetch('/json/products.json')
        .then(response => response.json())
        .then(data => {
            const selectedItem = data.find(i => i.Slug === id);
            setItem(selectedItem);
        });
    }, [id]);
    
    if (!item) return <Loader />;
    const bg = item.CategoryCode ? `url(/images/categories/banner/${item.CategoryCode}.avif)` : `url(/images/categories/banner/categories.avif)`;

    return (
        <>
            {item.Name && 
                <Seo 
                    title={item.Name} 
                    description={item.Seo} 
                    keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
                />
            }
            <ScrollToTopOnMount />
            <section className="page-banner text-white py-165 rpy-130" style={{ backgroundImage: bg }}>
                <div className="container">
                    <div className="banner-inner">
                        <h1 className="page-title wow fadeInUp delay-0-2s">{item.Name}</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/categories">Products</Link></li>
                                <li className="breadcrumb-item"><Link to={`/category/${item.CategoryCode}`}>{item.Categories}</Link></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>     
            <section className="product-details-area pt-80 pb-80 rpt-100">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <div className="product-preview-images rmb-55 wow fadeInLeft delay-0-2s">
                                <img onClick={handleShow} className="category-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src={`/images/products/webp/${item.SKU}.webp`} onError={(e) => {e.target.src = `/images/products/png/${item.SKU}.png`}} title={item.Name} alt="..." style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="product-details-content mb-30 wow fadeInRight delay-0-2s"> 
                                <div className="section-title mb-20">
                                    <span className='code'>Code: {item.SKU}</span>
                                    <h2>{item.Name}</h2>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: item.Seo }} />
                                <hr/>
                                <div className="off-ratting">
                                    <div className="ratting"><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                            className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></div>
                                </div>
                                <hr/>
                                <ul className="category-tags pt-10">
                                    <li><b>Category</b><span>:</span><Link to={`/category/${item.CategoryCode}`}>{item.Categories}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header className='text-center' closeButton>
                    <Modal.Title className='sub-title'>{item.Name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <Image src={`/images/products/webp/${item.SKU}.webp`} onError={(e) => {e.target.src = `/images/products/png/${item.SKU}.png`}} fluid />
                </Modal.Body>
            </Modal>            
        </>
    )
}
export default Product;