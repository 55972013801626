import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import Seo from '../components/Seo';
import SideMenu from '../components/SideMenu';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Loader from '../components/Loader';

const Category = () => {
    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch('/json/products.json')
            .then((response) => response.json())
            .then((data) => {
                const filteredData = data.filter(item => item.CategoryCode === id);
                setData(filteredData);
            });
        fetch('/json/categories.json')
            .then(response => response.json())
            .then(cat => {
                const selectedCat = cat.find(i => i.Code === id);
                setCat(selectedCat);
            });
      }, [id]);

      if (!cat) return <Loader />;
      const bg = cat.Code ? `url(/images/categories/banner/${cat.Code}.avif)` : `url(/images/categories/banner/categories.avif)`;

    return (
        <>
            {cat.Title && 
                <Seo 
                    title={cat.Title} 
                    description={cat.Description} 
                    keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
                />
            }
            <ScrollToTopOnMount />
            <section className="page-banner text-white py-165 rpy-130" style={{ backgroundImage: bg }}>
                <div className="container">
                    <div className="banner-inner">
                        <h1 className="page-title wow fadeInUp delay-0-2s">{cat.Title}</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/categories">Products</Link></li>
                                <li className="breadcrumb-item active">{cat.Title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="shop-page rel z-1 pt-50 rpt-90 pb-60 rpb-100">
                <div className="container bg-faded rouded-bottom pt-5 clearfix">
                    <p className='pb-20'>{cat.Description}</p>
                    <SideMenu />
                </div>
                <div className="container">
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 show-grid-row justify-content-center">
                        {data.map(item => (
                            <div key={item.SKU} className="col">
                                <Link to={`/product/${item.Slug}`} title={item.Name}>
                                    <div className="product-item wow bounceInDown delay-0-2s">
                                        <div className="image"><img src={`/images/products/webp/thumbs/${item.SKU}.webp`} onError={(e) => {e.target.src = `/images/products/png/thumbs/${item.SKU}.png`}} title={item.Name} alt="..." /></div>
                                        <div className="content pt-20">
                                        <div className="ratting"><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                        className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i></div>
                                            <h5>{item.Name}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <p className='text-center pb-50'><Link className="theme-btn mt-3 mb-5" to={`/categories`} title='Categories'> Back to Products </Link></p>
        </>
    )
}
export default Category;