import React from 'react';
import { Link } from 'react-router-dom';

const Social = () => {
    return (
        <>
            <Link to="https://facebook.com"><i className="bi bi-facebook"></i></Link>
            <Link to="https://instagram.com"><i className="bi bi-instagram"></i></Link>
        </>
    );
};

export default Social;