import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Seo from '../components/Seo';
import AboutUs from '../components/AboutUs';
import ContactForm from '../components/ContactForm';
import Loader from '../components/Loader';
import Sliders from '../components/Sliders';
import ReactGA from "react-ga4";

const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/json/categories.json')
      .then((response) => response.json())
      .then((data) => {
        const slicedItems = data.slice(0, 9)
        setData(slicedItems)}
      );
      ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home",
      });
  }, []);


  if (!data) return <Loader />;

    return (
        <>
          <Seo 
            title="Bakery Ingredients | All your baking needs" 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
          />
          <Sliders />
          <section id='services' className="contact-info-area rel z-1 pt-130 rpt-100">
            <div className="container section-title text-center" data-aos="fade-up">
              <h2>BlueBaker Product Range</h2>
            </div>
            <div className="container mt-50">

            <div className="row justify-content-between gy-4">
              {data.map(item => (
                <div key={item.Code} className="col-lg-4 col-md-6 mr-4" data-aos="fade-up" data-aos-delay="100">
                  <div className="about-feature-item style-two wow fadeInUp delay-0-4s" data-wow-duration="2s">
                    <div className="icon">
                    <Link to={`/category/${item.Code}`} title={item.Title}><img className="category-img mx-auto d-flex img-fluid mb-3 mb-lg-0" src={`/images/categories/icon/avif/${item.Code}.avif`} title={item.Title} alt="..." /></Link>
                    </div>
                    <h4>{item.Title}</h4>
                    <p>{item.Description}</p>
                    <Link to={`/category/${item.Code}`} title={item.Title} className="read-more color-secondary">Read more <i className="bi bi-arrow-right"></i></Link>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </section>   
          <section id="get-started" className="get-started section pt-50 pb-80 bg-lighter">

            <div className="container">

              <div className="row justify-content-between gy-4">

                <div className="col-lg-6 d-flex" data-aos="zoom-out" data-aos-delay="100">
                  <div className="content pt-20">
                    <h3>About us</h3>
                    <AboutUs />
                  </div>
                </div>

                <div className="col-lg-6 br-10" data-aos="zoom-out" data-aos-delay="200">
                  <div className="content bg-darker p-20">
                    <h3 className="text-white">Contact us</h3>
                    <ContactForm />
                  </div>
                </div>

              </div>

            </div>
          </section>  
        </>
    )
}

export default Home