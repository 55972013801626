import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import MyIcon from '../assets/images/bluebaker-logo.svg';
import Social from './Social';
import Shape1 from '../assets/images/shapes/footer-bg-shape.png';
import Footer1 from '../assets/images/shapes/footer1.png';
import Footer2 from '../assets/images/shapes/footer2.png';
import Footer3 from '../assets/images/shapes/footer3.png';
import Footer4 from '../assets/images/shapes/footer4.png';

const Footer = () => {
    const [data, setData] = useState([]);
    const [isVisible, setIsVisible] = useState([false]);

    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
      return () => {
        window.removeEventListener('scroll', toggleVisibility);
      };
    }, []);

    useEffect(() => {
      fetch('/json/categories.json')
        .then((response) => response.json())
        .then((data) => setData(data));
    }, []);
    
    return (
        <>
            <footer  className="main-footer bg-green text-white py-80">
                <div  className="container">
                    <div  className="row justify-content-center">
                        <div  className="col-lg-4 col-md-6 order-md-2">
                            <div  className="footer-widget about-widget text-center">
                                <div  className="footer-logo mb-30"><a href="/"><img src={MyIcon} alt="BlueBaker" width={180} /></a>
                                </div>
                                <p>All your baking needs</p>
                                <div className="social-style-two pt-10"><Social /></div>
                            </div>
                        </div>
                        <div  className="col-lg-4 col-md-6 order-md-1">
                            <div  className="footer-widget menu-widget two-column">
                                <h4  className="footer-title">Product Links</h4>
                                <ul>
                                    {data.map((item) => (
                                        <li key={item.Code}><Link to={`/category/${item.Code}`}>{item.Title}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div  className="col-lg-4 col-md-6 order-md-3">
                            <div  className="footer-widget contact-widget">
                                <h4  className="footer-title">Contact Us</h4>
                                <ul>
                                    <li><i  className="bi bi-pin-map"></i>Sharjah, United Arab Emirates</li>
                                    <li><i  className="bi bi-envelope"></i><a href="mailto:contact@bluebaker.me">contact@bluebaker.me</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div  className="copyright-area pt-25 pb-10">
                        <p>Copyright © 2025 BlueBaker. All Rights Reserved.</p>
                        <ul  className="footer-menu">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/categories">Products</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>{isVisible && <button onClick={scrollToTop} className="scroll-top scroll-to-target" data-target="html" style={{ display: "inline-block"}}><span
                                className="bi bi-arrow-up"></span></button>}
                    </div>
                </div>
                <div className="footer-shapes"><img className="footer-bg" src={Shape1} alt="Shape" /><img
        className="shape-one" src={Footer1} alt="Shape"/><img className="shape-two"
        src={Footer2} alt="Shape"/><img className="shape-three"
        src={Footer3} alt="Shape"/><img className="shape-four"
        src={Footer4} alt="Shape"/></div>
            </footer>

        </>
        
    );
  }
  
  export default Footer;