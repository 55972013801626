import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Seo from '../components/Seo';
import { Row, Col } from 'react-bootstrap';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Loader from '../components/Loader';

const CategoryList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/json/categories.json')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
    });
  }, []);

  //const getFirstWords = (str) => { return str.split(' ').slice(0, 5).join(' '); }

  if (!data) return <Loader />;

   
  return (
    <>
        <Seo 
            title="Products range " 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
        />
        <ScrollToTopOnMount />
        <section className="page-banner text-white py-165 rpy-130" style={{ backgroundImage: `url(/images/categories/banner/categories.avif)` }}>
            <div className="container">
                <div className="banner-inner">
                    <h1 className="page-title wow fadeInUp delay-0-2s">Our Products</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Products</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section> 
        <section className="portfolio-fluid-area rel z-1 py-130 rpy-100">
            <div className="container">
                <Row>
                    {data.map(x => (
                        <Col key={x.Code} xs={12} md={6}>
                            <div className="offer-banner-item color-two wow fadeInUp delay-0-2s" style={{ backgroundImage: `url(/images/categories/landscape/products/${x.Code}-bg.jpg)`,  minHeight: '280px' }}>
                                <div className="content">
                                    <span className="sub-title">BlueBaker</span>
                                    <h4>{x.Title}</h4>
                                    <Link className='theme-btn' to={`/category/${x.Code}`} title={x.Title}>View more <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>                            
                        </Col>

                    ))}
                </Row>
            </div>
        </section>

    </>
  );
};

export default CategoryList;
