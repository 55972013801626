import { useState } from 'react';
import Footer from './Footer';
import { Link, Outlet } from 'react-router-dom';
import MyIcon from '../assets/images/bluebaker-logo.svg';
import Social from './Social';
import Sidebar from './Sidebar';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const mobileNavToogle = () => {
        setShowMenu(!showMenu);
        return true;
    }
    const className = showMenu ? 'mobile-nav-toggle d-xl-none bi bi-x h1' : 'mobile-nav-toggle d-xl-none bi bi-list h1';
    const className1 = showMenu ? "navbar-collapse collapse clearfix show" : "navbar-collapse collapse clearfix";
    const sidebarToggle = (event) => {
        event.preventDefault();
        return document.querySelector('body').classList.toggle('side-content-visible');
    }

    return (
        <>
            <header className="main-header menu-absolute">
                <div className="header-top-wrap bg-light-green text-white py-10">
                    <div className="container-fluid">
                        <div className="header-top">
                        <div className="row">
                            <div className="col-xl-7 col-lg-6">
                                <div className="top-left">
                                    <ul>
                                        <li><i className="far fa-envelope"></i> <b>Email Us :</b> <Link
                                            href="mailto:contact@bluebaker.me" title='Mail to'>contact@bluebaker.me</Link></li>
                                        <li><i className="bi bi-clock"></i> <b>24hrs</b></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="top-right text-lg-right">
                                    <ul className='float-end'>
                                        <li><b>Bakery Ingredients</b></li>
                                        <li><div className="social-style-one"><Social /></div></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="header-upper navbar-light bg-light">
                    <div className="container-fluid clearfix">
                        <div className="header-inner d-flex align-items-center">
                        <div className="logo-outer">
                            <div className="logo">
                                <Link to="/" className="logo d-flex align-items-center pt-1">
                                    <img src={MyIcon} title='BlueBaker' alt='BlueBaker' width={120} />
                                </Link>
                            </div>
                        </div>
                        <div className="nav-outer clearfix">
                            <nav className="main-menu navbar-expand-lg mobile-nav">
                                <div className="navbar-header">
                                    <div className="mobile-logo my-15"><a href="/"><img
                                        src={MyIcon} alt="Logo" title="BlueBaker" width={160}/></a></div><button onClick={mobileNavToogle} type="button"
                                    className="navbar-toggle" data-toggle="collapse" data-target="#navbarNavDropdown"><i className={className} onClick={mobileNavToogle} /></button>
                                </div>
                                <div className={className1} id="navbarNavDropdown">
                                    <ul className="navigation clearfix d-none d-lg-flex">
                                        <li><Link onClick={mobileNavToogle} to='/'> Home </Link></li>
                                        <li><Link onClick={mobileNavToogle} to='/about'> About </Link></li>
                                        <li><Link onClick={mobileNavToogle} to='/categories'> Products </Link></li>
                                        <li><Link onClick={mobileNavToogle} to='/contact'> Contact </Link></li>
                                    </ul>
                                    <ul className="navigation clearfix d-block d-lg-none mobile-header">
                                        <li><Link onClick={mobileNavToogle} to='/'> Home </Link></li>
                                        <li><Link onClick={mobileNavToogle} to='/about'> About </Link></li>
                                        <li><Link onClick={mobileNavToogle} to='/categories'> Products </Link></li>
                                        <li><Link onClick={mobileNavToogle} to='/contact'> Contact </Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="menu-icons">
                            <Link onClick={sidebarToggle} className="theme-btn">Inquiry<i className="bi bi-arrow-right"></i></Link>
                        </div>
                        </div>
                    </div>
                </div>
            </header>
            <Sidebar />
            <Outlet />
            <Footer /> 
                 
        </>


    )
};
export default Header;