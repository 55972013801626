import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SideMenu = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    fetch('/json/categories.json')
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);

  return (
    <div className='float-end absolute right-20'>
      <Dropdown className='text-center' autoClose={true}>
        <Dropdown.Toggle className='p-2' variant="dark" id="dropdown-basic"> Select Category </Dropdown.Toggle>
        <Dropdown.Menu className='bg-light'>
            {data.map((item) => (
              <Dropdown.Item key={item.slno}><Link className='nav-link text-uppercase' to={`/category/${item.Code}`}>{item.Title}</Link></Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
          
    </div>

  );
};
export default SideMenu;