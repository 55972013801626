import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import IntroImage from '../assets/images/slides/avif/intro.avif';
import IntroImage1 from '../assets/images/slides/avif/intro-01.avif';
import IntroImage2 from '../assets/images/slides/avif/intro-02.avif';
import IntroImage3 from '../assets/images/slides/avif/intro-03.avif';
import IntroImage4 from '../assets/images/slides/avif/intro-04.avif';

const CarouselComponent = () => {
  return (
    <Carousel autoPlay={true} interval={2000} controls={true} indicators={false}>
      <Carousel.Item style={{ maxHeight: '760px'}}>
        <img
          className="d-block"
          src={IntroImage2}
          alt=""
        />
        <Carousel.Caption className='d-flex flex-column align-items-center justify-content-center h-100'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                  <div className="slider-content">
                      <div className="sub-title mb-10">BlueBaker</div>
                      <h1>Bakery Ingredients</h1>
                      <h6>All your baking needs</h6>
                      <div className="slider-btns mt-20">
                        <Button onClick={() => {
                          const element = document.getElementById('services');
                          element?.scrollIntoView({
                            behavior: 'smooth'
                          }); 
                        }} className="theme-btn">Our Catalog</Button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ maxHeight: '760px'}}>
        <img
          className="d-block"
          src={IntroImage1}
          alt=""
        />
        <Carousel.Caption className='d-flex flex-column align-items-center justify-content-center h-100'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                  <div className="slider-content">
                      <div className="sub-title mb-10">BlueBaker</div>
                      <h1>Bakery Ingredients</h1>
                      <h6>All your baking needs</h6>
                      <div className="slider-btns mt-20">
                        <Button onClick={() => {
                          const element = document.getElementById('services');
                          element?.scrollIntoView({
                            behavior: 'smooth'
                          }); 
                        }} className="theme-btn">Our Catalog</Button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ maxHeight: '760px'}}>
        <img
          className="d-block"
          src={IntroImage}
          alt=""
        />
        <Carousel.Caption className='d-flex flex-column align-items-center justify-content-center h-100'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                  <div className="slider-content">
                      <div className="sub-title mb-10">BlueBaker</div>
                      <h1>Bakery Ingredients</h1>
                      <h6>All your baking needs</h6>
                      <div className="slider-btns mt-20">
                        <Button onClick={() => {
                          const element = document.getElementById('services');
                          element?.scrollIntoView({
                            behavior: 'smooth'
                          }); 
                        }} className="theme-btn">Our Catalog</Button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ maxHeight: '760px'}}>
        <img
          className="d-block"
          src={IntroImage3}
          alt=""
        />
        <Carousel.Caption className='d-flex flex-column align-items-center justify-content-center h-100'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                  <div className="slider-content">
                      <div className="sub-title mb-10">BlueBaker</div>
                      <h1>Bakery Ingredients</h1>
                      <h6>All your baking needs</h6>
                      <div className="slider-btns mt-20">
                        <Button onClick={() => {
                          const element = document.getElementById('services');
                          element?.scrollIntoView({
                            behavior: 'smooth'
                          }); 
                        }} className="theme-btn">Our Catalog</Button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ maxHeight: '760px'}}>
        <img
          className="d-block"
          src={IntroImage4}
          alt=""
        />
        <Carousel.Caption className='d-flex flex-column align-items-center justify-content-center h-100'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                  <div className="slider-content">
                      <div className="sub-title mb-10">BlueBaker</div>
                      <h1>Bakery Ingredients</h1>
                      <h6>All your baking needs</h6>
                      <div className="slider-btns mt-20">
                        <Button onClick={() => {
                          const element = document.getElementById('services');
                          element?.scrollIntoView({
                            behavior: 'smooth'
                          }); 
                        }} className="theme-btn">Our Catalog</Button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;
