import React from 'react';
import ErroLogo from '../assets/images/404-icon.webp';
import { Link } from 'react-router-dom'
import Seo from '../components/Seo';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const NotFound = () => {
  return (
    <>
        <Seo 
            title="About us " 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
        />
        <ScrollToTopOnMount />
        <section className="page-banner text-white py-165 rpy-130" style={{ backgroundImage: `url(/images/categories/banner/categories.avif)` }}>
            <div className="container">
                <div className="banner-inner">
                    <h1 className="page-title wow fadeInUp delay-0-2s">404 Page Not Found</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Page Not Found</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>
        <section className="error-section rel z-1 py-130 rpy-100">
            <div className="container">
                <div className="error-inner text-center wow fadeInUp delay-0-2s">
                    <div className="image mb-70"><img src={ErroLogo} alt="404 Error" width={500} /></div>
                    <div className="content">
                        <div className="section-title mb-20">
                            <h2>Sorry This Page Can’t Be Found</h2>
                        </div>
                        <p>404 Page not Found!</p><Link
                            className="theme-btn style-three mt-15" to="/">Goto Home <i className="fas fa-angle-double-right"></i></Link>
                    </div>
                </div>
            </div>
        </section>
        
    </>
    )
}

export default NotFound;