import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Seo from "../components/Seo";
import ContactForm from '../components/ContactForm';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ReactGA from "react-ga4";

const Contact = () => {
    useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: "/contact",
          title: "Contact us",
        });
      });

    return (
        <>
        <Seo 
            title="Contact us " 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
        />
        <ScrollToTopOnMount />
        <section className="page-banner text-white py-165 rpy-130" style={{ backgroundImage: `url(/images/categories/banner/categories.avif)` }}>
            <div className="container">
                <div className="banner-inner">
                    <h1 className="page-title wow fadeInUp delay-0-2s">Contact us</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Contact us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section> 
        <section className="contact-info-area rel z-1 pt-60 pb-80 rpt-100">
            <div className='container'>
                <div className="section-title contact-title mb-55"><span className="sub-title mb-15">Contact With Us</span><h3>Send Us Message</h3></div>
            </div>
            <ContactForm />
        </section>
        </>
    );
};

export default Contact;