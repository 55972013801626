import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style.css';
import ReactGA from "react-ga4";
// G-D48EPPB0FR, G-5DSKHGZ15K
const TRACKING_IDS = ["G-D48EPPB0FR", "G-5DSKHGZ15K"];

ReactGA.initialize(TRACKING_IDS);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)